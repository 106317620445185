import React from 'react'
import 'normalize.css'
import './style.scss'

const Layout = ({
  children,
}) => {

  return (
      <main>
        {children}
      </main>
  )
}

export default Layout
